.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media (max-width: 599px), (max-height: 599px) {
	#app-bar-preliminare,
	#legend-preliminare {
		display: none;
	}
}

.leaflet-container {
	height: 100%;
	width: 100%;
}

.main-map .leaflet-draw {
	display: none;
}

body div#hubspot-messages-iframe-container {
	z-index: 1000 !important;
}

@media only screen and (max-width: 600px) {
	body div#hubspot-messages-iframe-container {
		display: none !important;
	}
	.main-map .leaflet-top {
		display: none !important;
	}
	.leaflet-control-layers {
		display: none !important;
	}
}

.leaflet-control-attribution {
	display: none;
}

.demLegend {
	width: 17rem;
	height: 2.5rem;
	border-radius: 5px;
}

.drag-handle {
	cursor: move;
}
